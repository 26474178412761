
import { defineComponent, defineAsyncComponent } from 'vue'

import { Driver } from '@/shared/interfaces/driver.interface'
import router from '@/router'
import { DriverActions } from '@/store/modules/drivers/actions'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { WorkShiftActions } from '@/store/modules/work-shifts/actions'
import { WorkShiftInterface } from '@/shared/interfaces/work-shift.interface'
import { useToast } from 'vue-toastification'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'

import { debounce } from 'lodash'
import DriversMap from '@/views/account/drivers/components/DriversMap.vue'
import UserWithAvatar from '@/shared/components/UserWithAvatar.vue'
import { VehiclesActions } from '@/store/modules/vehicles/actions'

export default defineComponent({
  components: {
    UserWithAvatar,
    DriversMap,
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    OrderByArrow: defineAsyncComponent(() => import('@/shared/OrderByArrow.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    TableGhost: defineAsyncComponent(() => import('@/shared/components/ghosts/TableGhost.vue')),
    InviteUser: defineAsyncComponent(() => import('@/views/shared/InviteUser.vue')),
    Pagination: defineAsyncComponent(() => import('@/shared/components/Pagination.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    ConfirmationModal: defineAsyncComponent(() => import('@/shared/components/modals/ConfirmationModal.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue'))
  },
  mixins: [],
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      height: 450,
      showModal: false,
      current: 1,
      shortTable: true,
      // isActive: false,
      showFilterModal: false,
      searchValue: '',
      assignShiftModal: false,
      clockOutModal: false,
      shiftId: null as any,
      darkstoreId: null as any,
      currentDarkstoreId: null as any,
      currentShiftId: null as any,
      selectedDriverId: 0,
      selectedDriverIdForClockOut: null as any,
      filterResult: null as any,
      selectedDriver: {} as any,
      sortFields: [
        { id: 'driver', currentSort: false, sort: null },
        { id: 'mobile', currentSort: false, sort: null },
        { id: 'vehicle', currentSort: false, sort: null },
        { id: 'drivingLicense', currentSort: false, sort: null },
        { id: 'created_at', currentSort: false, sort: null },
        { id: 'lastClockIn', currentSort: false, sort: null },
        { id: 'shift', currentSort: false, sort: null }
      ],
      filters: [
        {
          name: this.$t('filters.driverState'),
          model: 'isActive',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.active') },
            { id: 0, label: this.$t('filters.inactive') }
          ]
        },
        {
          name: this.$t('filters.driverStatus'),
          model: 'driverStatusIds',
          type: 'checkbox',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'fetchDriverStatuses',
          data: []
        },
        { name: this.$t('filters.joiningDate'), model: 'joiningDate', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.darkstores'),
          model: 'darkstoreIds',
          type: 'checkbox',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'fetchDarkStores',
          data: []
        },
        {
          name: this.$t('filters.isClockedIn'),
          model: 'isClockedIn',
          type: 'radio',
          data: [
            { id: 1, label: this.$t('filters.yes') },
            { id: 2, label: this.$t('filters.no') }
          ]
        },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        }
      ],
      driverIds: [] as any,
      interval: null as any,
      isManual: false
    }
  },
  validations: function () {
    return {
      shiftId: { required },
      darkstoreId: { required }
    }
  },
  computed: {
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    drivers (): Driver[] {
      return this.$store.getters['drivers/getDrivers']
    },
    locations (): any[] {
      return this.$store.getters['drivers/getClockedInLocations']
    },
    pagination (): PaginationInterface {
      return this.$store.getters['drivers/getPagination']
    },
    workShifts (): WorkShiftInterface[] {
      return this.$store.getters['workShifts/getWorkShiftsForSelect']
    },
    darkstores (): DarkStoreInterface[] {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    loading (): boolean {
      return this.$store.getters['drivers/getLoading']
    },
    fetchDarkstores (): any {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    fetchDriverStatuses (): any {
      return this.$store.getters['drivers/getDriverStatuses']
    }
  },
  mounted (): void {
    router.replace({ query: { ...this.$route.query }, replace: false })
    this.height = document.documentElement.clientHeight - 260
    addEventListener('resize', () => {
      this.height = document.documentElement.clientHeight - 260
    })
    // this.fetchLocations()
    this.interval = setInterval(() => {
      this.fetchLocations()
    }, 15000)
  },
  watch: {
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[5]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    },
    darkstoreId (val) {
      this.darkstoreId = val
      if (val !== this.currentDarkstoreId) {
        this.shiftId = null
      } else {
        this.shiftId = this.currentShiftId
      }
      this.getWorkShifts()
    },
    darkstores: {
      immediate: true,
      handler (val) {
        const target = this.filters[3]
        target.data = val
        target.dataLoading = false
      }
    },
    fetchDriverStatuses: {
      immediate: true,
      handler (val) {
        const target = this.filters[1]
        target.data = val
        target.dataLoading = false
      }
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'Drivers') {
          this.current = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
          if (val.query.search) {
            this.searchValue = val.query.search
          }
          this.fetchLocations()
          let params = JSON.parse(JSON.stringify(val.query))
          params = { pageSize: 10, pageNumber: 1, ...val.query }
          this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS}`, params)
        }
      }
    },
    drivers (drivers: Driver[]) {
      this.driverIds = drivers.map((driver: Driver) => {
        return driver.id
      })
    }
  },
  methods: {
    navigateToDetailed (id: number): void {
      router.push({ name: 'Profile', params: { id } })
    },
    fetchLocations (): void {
      const params = JSON.parse(JSON.stringify(this.$route.query))
      this.$store.dispatch(`drivers/${DriverActions.CLOCKED_IN_LOCATIONS}`, params)
    },
    openAssignShiftModal (driver: Driver): void {
      this.darkstoreId = driver.darkStoreId
      this.shiftId = driver.shift ? driver.shift.id : null
      this.getDarkStores()
      // this.getWorkShifts()
      this.assignShiftModal = true
      this.selectedDriverId = driver.id
      this.currentDarkstoreId = driver.darkStoreId
      this.currentShiftId = driver.shift ? driver.shift.id : null
    },
    openClockOutModal (driver: Driver): void {
      this.selectedDriverIdForClockOut = driver.id
      this.clockOutModal = true
    },
    resetForm (): void {
      this.selectedDriverId = 0
      this.shiftId = null
      this.darkstoreId = null
    },
    assignShift (event: any): void {
      event.preventDefault()
      const payload = {
        driverId: this.selectedDriverId,
        shiftId: this.shiftId,
        darkstoreId: this.darkstoreId
      }
      this.$store.dispatch(`drivers/${[DriverActions.ASSIGN_SHIFT]}`, payload).then(() => {
        const toast = useToast()
        toast.success('Shift assigned successfully', {
          timeout: 3000
        })
        this.resetForm()
        router.push({ query: { ...this.$route.query, pageNumber: 1 } })
        this.assignShiftModal = false
      })
    },
    getWorkShifts (pageNumber = 1, pageSize = 9999): void {
      this.$store.dispatch(`workShifts/${WorkShiftActions.FETCH_WORK_SHIFTS}`, { pageNumber, pageSize, darkstoreId: this.darkstoreId })
    },
    getDriverStatuses (): void {
      this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVER_STATUSES}`)
    },
    getDarkStores (pageNumber = 1, pageSize = 100): void {
      if (!this.darkstores.length) {
        this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNumber, pageSize })
      }
    },
    clickSort (field: any, currentSortName: string): void {
      this.sortFields.map((el: any) => {
        if (el.id === currentSortName) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      const queries = this.$route.query
      router.push({ query: { ...queries, orderBy: field.id, sort: field.sort } })
    },
    startAnimation (): void {
      const shiftsContainer: any = this.$refs.shiftsContent
      const shiftMap: any = this.$refs.shiftMap
      if (this.shortTable) {
        shiftsContainer.classList.add('animate-to-end')
        shiftMap.classList.add('w-0')
        shiftMap.classList.remove('calculated-width')
      } else {
        shiftsContainer.classList.remove('animate-to-end')
        shiftMap.classList.add('calculated-width')
        shiftMap.classList.remove('w-0')
      }
      this.shortTable = !this.shortTable
    },
    onSearchTextChange: debounce(function (this: any, searchTerm: string) {
      this.searchValue = searchTerm
    }, 500),

    onExpand (event: any): void {
      if (event === 'fetchDarkStores') {
        this.getDarkStores()
      }
      if (event === 'fetchDriverStatuses') {
        this.getDriverStatuses()
      }
      if (event === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
    },
    approveClockout (type: 0 | 1): void {
      if (type === 1) {
        this.$store.dispatch(`drivers/${DriverActions.FORCE_CLOCKOUT}`, {
          driverId: this.selectedDriverIdForClockOut,
          is_manual: this.isManual
        }).then(() => {
          const params = { pageSize: 10, pageNumber: 1, ...this.$route.query }
          this.$store.dispatch(`drivers/${DriverActions.FETCH_DRIVERS}`, params)
        }).finally(() => {
          this.clockOutModal = false
          this.isManual = false
        })
      } else {
        this.clockOutModal = false
        this.isManual = false
      }
    }
  },
  beforeUnmount () {
    clearInterval(this.interval)
  }
})
